import { gql } from '@apollo/client';

export const ADD_OFFICE_DAY = gql`
  mutation AddOfficeDay($input: OfficeDayInput!) {
    addOfficeDay(input: $input) {
      date
    }
  }
`;

export const DELETE_OFFICE_DAY = gql`
  mutation DeleteOfficeDay($date: AWSDate!) {
    deleteOfficeDay(date: $date)
  }
`;

export const UPDATE_DOOR = gql`
  mutation UpdateDoor($doorId: ID!, $locationId: ID, $name: String!) {
    updateDoor(doorId: $doorId, locationId: $locationId, name: $name) {
      domSerialNumber
      id
      location {
        id
        name
        type
      }
      name
      batteryWarningLevel
    }
  }
`;

export const UPDATE_DOOR_WITH_OFFICE_MODE = gql`
  mutation UpdateDoorWithOfficeMode(
    $doorId: ID!
    $locationId: ID
    $name: String!
    $officeMode: UpdateDoorOfficeModeInput
  ) {
    updateDoor(doorId: $doorId, locationId: $locationId, name: $name, officeMode: $officeMode) {
      domSerialNumber
      id
      location {
        id
        name
        type
      }
      name
      batteryWarningLevel
      officeMode {
        ... on WeekSchedule {
          weekDays {
            dayName
            from
            to
          }
        }
      }
    }
  }
`;

export const DELETE_DOOR_BY_ID = gql`
  mutation DeleteDoorById($doorId: ID!) {
    deleteDoorById(doorId: $doorId)
  }
`;

export const ASSIGN_ACCESS_GRANTS_TO_DOORS = gql`
  mutation AssignAccessGrantsToDoors($doorIds: [ID!]!, $accessGrantIds: [ID!]!) {
    assignAccessGrantsToDoors(doorIds: $doorIds, accessGrantIds: $accessGrantIds) {
      id
      doors {
        id
        name
        location {
          id
          name
          type
        }
        domSerialNumber
        batteryWarningLevel
      }
    }
  }
`;

export const UNASSIGN_ACCESS_GRANTS_FROM_DOORS = gql`
  mutation UnassignAccessGrantsFromDoors($doorIds: [ID!]!, $accessGrantIds: [ID!]!) {
    unassignAccessGrantsFromDoors(doorIds: $doorIds, accessGrantIds: $accessGrantIds) {
      id
      doors {
        id
        name
        location {
          id
          name
          type
        }
        domSerialNumber
        batteryWarningLevel
      }
    }
  }
`;

export const SET_ACCESS_GRANTS_FOR_GROUPS = gql`
  mutation SetAccessGrantsForGroups(
    $visitorGroupId: ID!
    $defaultAccessGrantIds: [ID!]!
    $scheduledAccessGrantIds: [ID!]!
  ) {
    setAccessGrantsForGroups(
      visitorGroupId: $visitorGroupId
      defaultAccessGrantIds: $defaultAccessGrantIds
      scheduledAccessGrantIds: $scheduledAccessGrantIds
    ) {
      id
      name
      externalRef
      defaultAccessGrants {
        id
        doors {
          id
          name
          location {
            id
            name
            type
          }
          domSerialNumber
          batteryWarningLevel
        }
      }
      scheduledAccessGrants {
        id
        doors {
          id
          name
          location {
            id
            name
            type
          }
          domSerialNumber
          batteryWarningLevel
        }
      }
      type
    }
  }
`;

export const UNREGISTER_MOBILE_DEVICE_BY_ID = gql`
  mutation UnregisterMobileDeviceById($visitorId: ID!, $mobileDeviceId: ID!) {
    unregisterMobileDeviceById(visitorId: $visitorId, mobileDeviceId: $mobileDeviceId)
  }
`;

export const DELETE_VISITOR_BY_ID = gql`
  mutation DeleteVisitorById($visitorId: ID!) {
    deleteVisitorById(visitorId: $visitorId)
  }
`;

export const DELETE_VISITOR_GROUP_BY_ID = gql`
  mutation DeleteVisitorGroupById($visitorGroupId: ID!, $withVisitors: Boolean) {
    deleteVisitorGroupById(visitorGroupId: $visitorGroupId, withVisitors: $withVisitors)
  }
`;

export const SET_VISITOR = gql`
  mutation SetVisitor(
    $email: AWSEmail!
    $name: String!
    $visitorGroupExternalRefs: [String!]
    $expirationDate: AWSDateTime
  ) {
    setVisitor(
      email: $email
      name: $name
      visitorGroupExternalRefs: $visitorGroupExternalRefs
      expirationDate: $expirationDate
    ) {
      id
      name
      email
      mobileDevices {
        id
        isRegistered
        registrationToken
        registrationDate
        role
      }
      isEditable
      expirationDate
    }
  }
`;

export const SET_VISITOR_BY_ID = gql`
  mutation SetVisitorById(
    $visitorId: ID!
    $name: String!
    $visitorGroupExternalRefs: [String!]
    $expirationDate: AWSDateTime
  ) {
    setVisitorById(
      visitorId: $visitorId
      name: $name
      visitorGroupExternalRefs: $visitorGroupExternalRefs
      expirationDate: $expirationDate
    ) {
      id
      name
      email
      mobileDevices {
        id
        isRegistered
        registrationToken
        registrationDate
        role
      }
      isEditable
      expirationDate
    }
  }
`;

export const SET_VISITOR_BY_ID_WITH_ROLE = gql`
  mutation SetVisitorByIdWithRole(
    $visitorId: ID!
    $name: String!
    $expirationDate: AWSDateTime
    $visitorGroupMembership: [VisitorGroupMembershipInput!]!
  ) {
    setVisitorById(
      visitorId: $visitorId
      name: $name
      expirationDate: $expirationDate
      visitorGroupMembership: $visitorGroupMembership
    ) {
      id
      name
      email
      mobileDevices {
        id
        isRegistered
        registrationToken
        registrationDate
        role
      }
      isEditable
      expirationDate
      visitorGroupMembership {
        visitorGroup {
          externalRef
          type
        }
        role
      }
    }
  }
`;

export const ADD_VISITOR_WITH_ROLE = gql`
  mutation AddVisitorWithRole(
    $email: AWSEmail!
    $name: String!
    $expirationDate: AWSDateTime
    $visitorGroupMembership: [VisitorGroupMembershipInput!]
  ) {
    addVisitor(
      email: $email
      name: $name
      expirationDate: $expirationDate
      visitorGroupMembership: $visitorGroupMembership
    ) {
      id
      name
      email
      mobileDevices {
        id
        isRegistered
        registrationToken
        registrationDate
        role
      }
      isEditable
      expirationDate
      visitorGroupMembership {
        visitorGroup {
          externalRef
          type
        }
        role
      }
    }
  }
`;

export const UPDATE_ACCESS_GRANT = gql`
  mutation UpdateAccessGrant($accessGrantId: ID!, $input: UpdateAccessGrantInput!) {
    updateAccessGrant(accessGrantId: $accessGrantId, input: $input) {
      id
      accessSchedule {
        ... on WeekSchedule {
          weekDays {
            dayName
            from
            to
          }
        }
      }
    }
  }
`;

export const ADD_VISITOR_GROUP = gql`
  mutation AddVisitorGroup($name: String!, $type: String!) {
    addVisitorGroup(name: $name, type: $type) {
      id
      name
      members {
        id
        name
        email
        mobileDevices {
          id
          isRegistered
          registrationToken
          registrationDate
          role
        }
        isEditable
        expirationDate
      }
      type
      externalRef
    }
  }
`;

export const ADD_VISITOR_GROUP_WITH_RETAIL = gql`
  mutation AddVisitorGroupWithRetail($name: String!, $type: String!, $maxNumberOfMembers: Int) {
    addVisitorGroup(name: $name, type: $type, maxNumberOfMembers: $maxNumberOfMembers) {
      id
      name
      members {
        id
        name
        email
        mobileDevices {
          id
          isRegistered
          registrationToken
          registrationDate
          role
        }
        isEditable
        expirationDate
      }
      type
      externalRef
      maxNumberOfMembers
    }
  }
`;

export const UPDATE_VISITOR_GROUP = gql`
  mutation UpdateVisitorGroup($visitorGroupId: ID!, $name: String!) {
    updateVisitorGroup(visitorGroupId: $visitorGroupId, name: $name) {
      id
      name
      members {
        id
        name
        email
        mobileDevices {
          id
          isRegistered
          registrationToken
          registrationDate
          role
        }
        isEditable
        expirationDate
      }
      type
      externalRef
    }
  }
`;

export const UPDATE_VISITOR_GROUP_WITH_RETAIL = gql`
  mutation UpdateVisitorGroupWithRetail($visitorGroupId: ID!, $name: String!, $maxNumberOfMembers: Int) {
    updateVisitorGroup(visitorGroupId: $visitorGroupId, name: $name, maxNumberOfMembers: $maxNumberOfMembers) {
      id
      name
      members {
        id
        name
        email
        mobileDevices {
          id
          isRegistered
          registrationToken
          registrationDate
          role
        }
        isEditable
        expirationDate
        visitorGroupMembership {
          visitorGroup {
            externalRef
            type
          }
          role
        }
      }
      type
      externalRef
      maxNumberOfMembers
    }
  }
`;

export const SET_INSTALLER = gql`
  mutation SetInstaller($email: AWSEmail!, $name: String!) {
    setInstaller(email: $email, name: $name) {
      id
      name
      email
      mobileDevices {
        id
        isRegistered
        registrationToken
        registrationDate
        role
      }
      isEditable
      expirationDate
    }
  }
`;

export const UNSET_INSTALLER_BY_ID = gql`
  mutation UnsetInstallerById($visitorId: ID!) {
    unsetInstallerById(visitorId: $visitorId) {
      id
      name
      email
      mobileDevices {
        id
        isRegistered
        registrationToken
        registrationDate
        role
      }
      isEditable
      expirationDate
    }
  }
`;

export const SEND_INVITATION_FOR_MOBILE_DEVICE_BY_ID = gql`
  mutation SendInvitationForMobileDeviceById($visitorId: ID!, $mobileDeviceId: ID!) {
    sendInvitationForMobileDeviceById(visitorId: $visitorId, mobileDeviceId: $mobileDeviceId)
  }
`;

export const SET_IS_ADMIN_FOR_GROUP = gql`
  mutation SetIsAdminForGroup($externalRef: String!, $isAdmin: Boolean!) {
    setIsAdminForGroup(externalRef: $externalRef, isAdmin: $isAdmin)
  }
`;

export const UPDATE_ACCESS_GRANT_WITH_IS_OFFICE_MODE_ENABLED = gql`
  mutation SetAccessGrantsIsOfficeModeEnabled($accessGrantId: ID!, $isOfficeModeEnabled: Boolean!) {
    setAccessGrantsIsOfficeModeEnabled(accessGrantId: $accessGrantId, isOfficeModeEnabled: $isOfficeModeEnabled) {
      id
      isOfficeModeEnabled
    }
  }
`;

export const ADD_LOCATION = gql`
  mutation AddLocation($location: LocationInput!) {
    addLocation(location: $location) {
      id
      name
      externalRef
      doors {
        id
        name
        location {
          id
          name
          type
        }
        domSerialNumber
        batteryWarningLevel
      }
      type
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($id: ID!, $location: LocationInput!) {
    updateLocation(id: $id, location: $location) {
      id
      name
      externalRef
      doors {
        id
        name
        location {
          id
          name
          type
        }
        domSerialNumber
        batteryWarningLevel
      }
      type
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation DeleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      id
    }
  }
`;

export const UPDATE_DOOR_WITH_LOCATIONS = gql`
  mutation UpdateDoorWithLocations(
    $doorId: ID!
    $externalLocationId: ID
    $manualLocationIds: [ID!]
    $name: String!
    $officeMode: UpdateDoorOfficeModeInput
  ) {
    updateDoor(
      doorId: $doorId
      externalLocationId: $externalLocationId
      manualLocationIds: $manualLocationIds
      name: $name
      officeMode: $officeMode
    ) {
      domSerialNumber
      id
      externalLocation {
        id
        name
        type
      }
      manualLocations {
        id
        name
        externalRef
        type
      }
      name
      batteryWarningLevel
      officeMode {
        ... on WeekSchedule {
          weekDays {
            dayName
            from
            to
          }
        }
      }
    }
  }
`;

export const ASSIGN_TO_ACCESS_GRANTS = gql`
  mutation AssignToAccessGrants($accessGrantIds: [ID!]!, $doorIds: [ID!]!, $locationIds: [ID!]!) {
    assignToAccessGrants(accessGrantIds: $accessGrantIds, doorIds: $doorIds, locationIds: $locationIds) {
      id
      accessSchedule {
        ... on WeekSchedule {
          weekDays {
            dayName
            from
            to
          }
        }
      }
      doors {
        domSerialNumber
        id
        externalLocation {
          id
          name
        }
        manualLocations {
          id
          name
          externalRef
          type
        }
        name
        batteryWarningLevel
        officeMode {
          ... on WeekSchedule {
            weekDays {
              dayName
              from
              to
            }
          }
        }
      }
      locations {
        id
        name
        externalRef
        doors {
          id
          name
          location {
            id
            name
            type
          }
          domSerialNumber
          batteryWarningLevel
        }
        type
      }
    }
  }
`;

export const UNASSIGN_TO_ACCESS_GRANTS = gql`
  mutation UnassignToAccessGrants($accessGrantIds: [ID!]!, $doorIds: [ID!]!, $locationIds: [ID!]!) {
    unassignFromAccessGrants(accessGrantIds: $accessGrantIds, doorIds: $doorIds, locationIds: $locationIds) {
      id
      accessSchedule {
        ... on WeekSchedule {
          weekDays {
            dayName
            from
            to
          }
        }
      }
      doors {
        domSerialNumber
        id
        externalLocation {
          id
          name
        }
        manualLocations {
          id
          name
          externalRef
          type
        }
        name
        batteryWarningLevel
        officeMode {
          ... on WeekSchedule {
            weekDays {
              dayName
              from
              to
            }
          }
        }
      }
      locations {
        id
        name
        externalRef
        doors {
          id
          name
          location {
            id
            name
            type
          }
          domSerialNumber
          batteryWarningLevel
        }
        type
      }
    }
  }
`;

export const ACCEPT_LEGAL_DOCUMENTS = gql`
  mutation AcceptLegalDocuments($acceptedLegalDocuments: AcceptedLegalDocuments!) {
    acceptLegalDocuments(acceptedLegalDocuments: $acceptedLegalDocuments) {
      ... on AgreementRejected {
        reason
      }
      ... on AgreementAccepted {
        acceptedAt
      }
    }
  }
`;
