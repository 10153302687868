import { makeStyles } from '@material-ui/core';
import { FlattenSimpleInterpolation } from 'styled-components';
import { handleVariantDetails, TypographyVariant } from 'ui/atoms/Typography/Typography';

export interface Colors {
  bgLight: string;
  bgDark: string;
  bgRedish: string;
  bgBlur: string;
  bgDarkModal: string;
  b1: string;
  b2: string;
  b3: string;
  b4: string;
  b5: string;
  b6: string;
  primary: string;
  primaryShade: string;
  onPrimary: string;
  secondary: string;
  onSecondary: string;
  dTextHigh: string;
  dTextMedium: string;
  dTextLow: string;
  lTextHigh: string;
  lTextMedium: string;
  lTextLow: string;
  success: string;
  success1: string;
  warning: string;
  warning1: string;
  error: string;
  error1: string;
  transparent: string;
  redish: string;
  yellowish: string;
  greenish: string;
}

interface Typography {
  fontFamily: string;
  size: number;
  variant: (variant: TypographyVariant) => FlattenSimpleInterpolation;
}

export interface Theme {
  colors: Colors;
  borderRadius: number;
  typography: Typography;
  isMobile: boolean;
  isMobileLandscape: boolean;
}

export const colors: Colors = {
  bgLight: 'hsl(0, 0%, 100%)',
  bgDark: 'hsl(0, 0%, 15%)',
  bgRedish: 'hsl(0, 100%, 99%)',
  bgBlur: 'hsl(0, 0%, 77%)',
  bgDarkModal: 'hsl(224, 14%, 15%)',
  b1: 'hsl(60, 14%, 13%)',
  b2: 'hsl(0, 0%, 17%)',
  b3: 'hsl(60, 2%, 22%)',
  b4: 'hsl(60, 1%, 26%)',
  b5: 'hsl(60, 29%, 97%)',
  b6: 'hsl(71, 20%, 89%)',
  primary: 'hsl(60, 100%, 40%)',
  primaryShade: 'hsl(65, 100%, 32%)',
  onPrimary: 'hsl(0, 0%, 100%)',
  secondary: 'hsl(225, 6%, 41%)',
  onSecondary: 'hsl(0, 0%, 100%)',
  dTextHigh: 'hsl(65, 12%, 19%)',
  dTextMedium: 'hsl(60, 2%, 59%)',
  dTextLow: 'hsl(0, 0%, 80%)',
  lTextHigh: 'hsl(0, 0%, 100%)',
  lTextMedium: 'hsl(60, 0%, 57%)',
  lTextLow: 'hsl(60, 1%, 36%)',
  success: 'hsl(137, 69%, 46%)',
  success1: 'hsl(137, 65%, 43%)',
  warning: 'hsl(48, 89%, 50%)',
  warning1: 'hsl(48, 100%, 50%)',
  error: 'hsl(7, 69%, 46%)',
  error1: 'hsl(4, 65%, 43%)',
  transparent: 'transparent',
  redish: 'hsl(0, 100%, 99%)',
  yellowish: 'hsl(43, 100%, 99%)',
  greenish: 'hsl(135, 50%, 98%)'
};

const typography: Typography = {
  fontFamily: 'Poppins',
  size: 16,
  variant: (variant) => handleVariantDetails(variant)
};

const theme: Theme = {
  colors,
  borderRadius: 4,
  typography,
  isMobile: false,
  isMobileLandscape: false
};

export const overrideMuiStyles = {
  root: {
    typography: {
      fontFamily: typography.fontFamily
    },
    overrides: {
      MuiDrawer: {
        paper: {
          boxShadow: 'unset'
        },
        modal: {
          background: colors.bgDarkModal
        }
      }
    }
  }
};

export const useStyles = makeStyles(overrideMuiStyles);

export default theme;
