import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import Typography from 'ui/atoms/Typography/Typography';
import Button from 'ui/atoms/Button/Button';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export interface Props {
  handleButtonOnClick: () => void;
}

const WelcomePage: React.FC<Props> = ({ handleButtonOnClick }) => (
  <>
    <BackgroundImage id="background" />
    <Wrapper>
      <ComponentWrapper gap="0.5rem">
        <Typography variant="title1" color="lTextHigh">
          Welcome to
        </Typography>
        <Typography variant="title1" color="primary">
          ATLAS
        </Typography>
      </ComponentWrapper>
      <Button onClick={handleButtonOnClick}>Start setup</Button>
    </Wrapper>
  </>
);

export default WelcomePage;
