import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import Button from 'ui/atoms/Button/Button';
import Typography from 'ui/atoms/Typography/Typography';
import Checkbox from 'ui/atoms/Checkbox/Checkbox';
import Title from 'ui/atoms/Title/Title';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const TitleWrapper = styled.div`
  position: absolute;
  top: 4rem;
  left: 8rem;
`;

const Wrapper = styled.div`
  position: absolute;
  top: calc(50% + 4rem);
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const RulesWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.dTextMedium};
  height: 55vh;
  overflow: auto;

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.b5};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.b6};
  }
`;

export interface Props {
  organizationName: string;
  rulesContent: string;
  rulesName: string;
  isCheckBoxChecked: boolean;
  handleCheckBoxOnClick: () => void;
  handleButtonOnClick: () => void;
}

const RulesPage: React.FC<Props> = ({
  rulesContent,
  rulesName,
  isCheckBoxChecked,
  handleButtonOnClick,
  handleCheckBoxOnClick
}) => {
  const rulesDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rulesDivRef.current) {
      rulesDivRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [rulesContent]);

  return (
    <>
      <BackgroundImage id="background" />
      <TitleWrapper>
        <Title />
      </TitleWrapper>
      <Wrapper>
        <ComponentWrapper
          backgroundColor="bgLight"
          flexDirection="column"
          width="104rem"
          height="54.5rem"
          padding="3rem 5rem"
          gap="1rem"
        >
          <Typography variant="title1" color="dTextHigh">
            {`ATLAS Access – ${rulesName}`}
          </Typography>
          <RulesWrapper ref={rulesDivRef}>
            <ComponentWrapper flexDirection="column" gap="1.5rem" padding="1rem 4rem">
              <Typography color="dTextHigh">{rulesContent}</Typography>
            </ComponentWrapper>
          </RulesWrapper>
          <ComponentWrapper alignSelf="flex-start" gap="1rem" alignItems="center" justifyContent="center">
            <Checkbox checked={isCheckBoxChecked} onClick={handleCheckBoxOnClick} checkboxType="dark" />
            <Typography variant="body1" color="dTextHigh">
              {`I have read and agree to the terms of the <b>${rulesName}</b>`}
            </Typography>
          </ComponentWrapper>
          <ComponentWrapper width="15rem" alignSelf="flex-end">
            <Button
              disabled={!isCheckBoxChecked}
              backgroundColor={!isCheckBoxChecked ? 'b5' : 'primary'}
              textColor={!isCheckBoxChecked ? 'lTextMedium' : 'lTextHigh'}
              onClick={handleButtonOnClick}
            >
              I agree
            </Button>
          </ComponentWrapper>
        </ComponentWrapper>
      </Wrapper>
    </>
  );
};

export default RulesPage;
