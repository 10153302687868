import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import Typography from 'ui/atoms/Typography/Typography';
import Title from 'ui/atoms/Title/Title';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const InvalidOTR: React.FC = () => (
  <>
    <BackgroundImage id="background" />
    <ComponentWrapper margin="4rem 0 0 8rem">
      <Title />
    </ComponentWrapper>
    <Wrapper>
      <ComponentWrapper width="104rem" backgroundColor="bgLight" flexDirection="column" padding="5rem 5rem" gap="1rem">
        <Typography variant="title1" color="dTextHigh">
          Link expired or already used
        </Typography>
        <ComponentWrapper flexDirection="column">
          <Typography variant="body1" color="dTextHigh">
            The URL you have clicked is no longer valid. This may be due to the link expiring or being used already.
          </Typography>
          <Typography variant="body1" color="dTextHigh">
            {`If you believe this is an error, please contact your reseller or
            <a href="https://tkhsecurity.com/">TKH Security</a> for assistance`}
          </Typography>
        </ComponentWrapper>
      </ComponentWrapper>
    </Wrapper>
  </>
);

export default InvalidOTR;
