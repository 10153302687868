import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import Button from 'ui/atoms/Button/Button';
import Typography from 'ui/atoms/Typography/Typography';
import Title from 'ui/atoms/Title/Title';
import Separator from 'ui/atoms/Separator/Separator';
import Icon from 'ui/atoms/Icon/Icon';
import Alert from '@mui/material/Alert';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const TitleWrapper = styled.div`
  position: absolute;
  top: 4rem;
  left: 8rem;
`;

const Wrapper = styled.div`
  position: absolute;
  top: calc(50% + 4rem);
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export interface Props {
  onConnectButtonClick: () => void;
}

const ConnectEntraID: React.FC<Props> = ({ onConnectButtonClick }) => (
  <>
    <BackgroundImage id="background" />
    <TitleWrapper>
      <Title />
    </TitleWrapper>
    <Wrapper>
      <ComponentWrapper
        backgroundColor="bgLight"
        flexDirection="column"
        height="54.5rem"
        width="104rem"
        padding="3rem 5rem"
        gap="1rem"
      >
        <Typography variant="title1" color="dTextHigh">
          Connecting Microsoft EntraID
        </Typography>
        <Typography variant="body1" color="dTextHigh">
          To connect your Microsoft EntraID environment with ATLAS, follow these steps:
        </Typography>
        <ComponentWrapper flexDirection="column" gap="1rem">
          <Separator height="0.0625rem" />
          <ComponentWrapper
            flexDirection="row"
            gap="1.5rem"
            padding="1rem 0"
            width="100%"
            justifyContent="space-between"
          >
            <ComponentWrapper gap="2rem">
              <Icon name="EntraId" height={36} width={36} />
              <Typography color="dTextHigh">Microsoft EntraID</Typography>
            </ComponentWrapper>
            <ComponentWrapper width="13.75rem" gap="0.5rem">
              <Button onClick={onConnectButtonClick}>Connect</Button>
            </ComponentWrapper>
          </ComponentWrapper>
          <ComponentWrapper flexDirection="column" padding="1rem">
            <Typography color="dTextHigh" variant="body1">
              {`ATLAS will begin by installing the Sync and Authorization Enterprise applications in Microsoft EntraID.  Afterwards, you can select which EntraID user groups should be synchronized to ATLAS. <br><br>This can be done in two different ways:`}
            </Typography>
            <ComponentWrapper margin="1.5rem 2rem">
              <Typography color="dTextHigh" variant="body1">
                {`<b>“ATLAS” prefix</b> (Available to all tiers)<br> Prefix the names of the user groups you wish to sync with “ATLAS” (e.g. “ATLAS Employees”). All groups with this prefix will be automatically synchronized with ATLAS.<br><br>
          <b>ATLAS Sync app</b> (Enterprise tier only)<br> Using the ATLAS Sync Enterprise app to select specific user groups in EntraID that should be synced with ATLAS`}
              </Typography>
            </ComponentWrapper>
            <ComponentWrapper flexDirection="column" gap="1.5rem">
              <Alert severity="warning">
                <Typography color="dTextHigh" variant="body1">
                  {`<b>Important:</b> ATLAS is role based access, and can only be synced with user groups. Not individual users.`}
                </Typography>
              </Alert>
              <Alert severity="warning">
                <Typography color="dTextHigh" variant="body1">
                  {`<b>Important:</b> It is imperative that the EntraID Global Admin is included in at least one of the synchronized
              user groups. Without this, the Global Admin will not be able to log in to ATLAS using their Microsoft
              credentials.`}
                </Typography>
              </Alert>
            </ComponentWrapper>
          </ComponentWrapper>
          <Separator height="0.0625rem" />
        </ComponentWrapper>
      </ComponentWrapper>
    </Wrapper>
  </>
);

export default ConnectEntraID;
