import React, { useState } from 'react';
import styled from 'styled-components';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import Button from 'ui/atoms/Button/Button';
import Typography from 'ui/atoms/Typography/Typography';
import Title from 'ui/atoms/Title/Title';
import Separator from 'ui/atoms/Separator/Separator';
import Icon from 'ui/atoms/Icon/Icon';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const TitleWrapper = styled.div`
  position: absolute;
  top: 4rem;
  left: 8rem;
`;

const Wrapper = styled.div`
  position: absolute;
  top: calc(50% + 4rem);
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export interface Props {
  onGoToAtlasWebPortalButtonClick: () => void;
}

const AlmostReady: React.FC<Props> = ({ onGoToAtlasWebPortalButtonClick }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleClickMoreInfo = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      <BackgroundImage id="background" />
      <TitleWrapper>
        <Title />
      </TitleWrapper>
      <Wrapper>
        <ComponentWrapper
          backgroundColor="bgLight"
          flexDirection="column"
          width="104rem"
          height={isCollapsed ? '22.25rem' : '35.375rem'}
          padding="3rem 5rem"
          gap="1rem"
        >
          <ComponentWrapper height="3rem">
            <Typography variant="title1" color="dTextHigh">
              Almost ready
            </Typography>
          </ComponentWrapper>
          <ComponentWrapper height="3rem" justifyContent="space-between">
            <Typography variant="body1" color="dTextHigh">
              {`ATLAS Enterprise apps have been installed. Once user groups have been synchronized, you are able to login at the ATLAS Web portal.<br>
        This process can take up to an hour, depending on the amount of users that needs to be synced.`}
            </Typography>
            <ComponentWrapper
              onClick={handleClickMoreInfo}
              alignItems="flex-start"
              justifyContent="center"
              cursor="pointer"
            >
              <Typography variant="body1" color={isCollapsed ? 'dTextHigh' : 'primary'}>
                more info
              </Typography>
              <ComponentWrapper alignItems="center" justifyContent="center">
                <Icon
                  name={isCollapsed ? 'ArrowDown' : 'ArrowUp'}
                  color="transparent"
                  stroke={isCollapsed ? 'lTextMedium' : 'primary'}
                  viewBox="0 0 16 8"
                  width={16}
                  height={16}
                />
              </ComponentWrapper>
            </ComponentWrapper>
          </ComponentWrapper>
          {!isCollapsed && (
            <>
              <Separator height="0.0625rem" />
              <ComponentWrapper height="1.6875rem">
                <Typography variant="body1" color="dTextHigh">
                  To synchronize users with ATLAS, use one of the following two ways:
                </Typography>
              </ComponentWrapper>
              <ComponentWrapper margin="0 2rem" height="10.4375rem">
                <Typography color="dTextHigh" variant="body1">
                  {`<b>“ATLAS” prefix</b> (Available to all tiers)<br> Prefix the names of the user groups you wish to sync with “ATLAS” (e.g. “ATLAS Employees”). All groups with this prefix will be automatically synchronized with ATLAS.<br>
                  <br>
                  <b>ATLAS Sync app</b> (Enterprise tier only)<br>
                  Using the ATLAS Sync Enterprise app to select specific user groups in EntraID that should be synced with ATLAS`}
                </Typography>
              </ComponentWrapper>
            </>
          )}
          <Separator height="0.0625rem" />
          <ComponentWrapper alignSelf="center" width="21rem" padding="2rem">
            <Button onClick={onGoToAtlasWebPortalButtonClick}>Go to ATLAS web portal</Button>
          </ComponentWrapper>
        </ComponentWrapper>
      </Wrapper>
    </>
  );
};
export default AlmostReady;
