import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useTenantLegalDocuments from 'hooks/useTenantLegalDocuments/useTenantLegalDocuments';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import InvalidOTR from 'ui/templates/InvalidOTR/InvalidOTR';
import RulesPage from 'ui/templates/RulesPage/RulesPage';
import WelcomePage from 'ui/templates/WelcomePage/WelcomePage';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { tenantsServiceTokenVar } from 'state/vars';
import Loader from 'ui/atoms/Loader/Loader';
import ConnectEntraID from 'ui/templates/ConnectEntraID/ConnectEntraID';
import AlmostReady from 'ui/templates/ConnectEntraID/AlmostReady';

const LicensePortalPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  tenantsServiceTokenVar(id);
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { tenantLegalDocuments, getTenantLegalDocuments, acceptedLegalDocuments, acceptedLegalDocumentsLoading } =
    useTenantLegalDocuments({
      handleFetchError
    });
  const [isOTRValid, setIsOTRValid] = useState(false);
  const [isProcessStarted, setIsProcessStarted] = useState(false);
  const [isSLACheckBoxSelected, setIsSLACheckBoxSelected] = useState(false);
  const [isSLARulesAccepted, setIsSLARulesAccepted] = useState(false);
  const [isPDACheckBoxSelected, setIsPDACheckBoxSelected] = useState(false);
  const [isConnectedStarted, setIsConnectedStarted] = useState(false);

  const handleSLACheckBoxClick = () => {
    setIsSLACheckBoxSelected(!isSLACheckBoxSelected);
  };

  const handleSLAButtonOnClick = () => {
    if (isSLACheckBoxSelected) setIsSLARulesAccepted(true);
  };

  const handleDPACheckBoxClick = () => {
    setIsPDACheckBoxSelected(!isPDACheckBoxSelected);
  };

  const handleDPAButtonOnClick = () => {
    if (!acceptedLegalDocumentsLoading && tenantLegalDocuments) {
      acceptedLegalDocuments({
        dpa: tenantLegalDocuments.dpa.id,
        sla: tenantLegalDocuments.sla.id
      });
    }
  };

  const handleWelcomePageButtonOnClick = () => {
    getTenantLegalDocuments();
  };

  useEffect(() => {
    if (tenantLegalDocuments?.dpa.id && tenantLegalDocuments.sla.id) {
      setIsOTRValid(true);
      setIsProcessStarted(true);
    }
  }, [tenantLegalDocuments]);

  useEffect(() => {
    if (id === '' || id === undefined || id === null) setIsOTRValid(false);
  }, [id]);

  if (!isProcessStarted) return <WelcomePage handleButtonOnClick={handleWelcomePageButtonOnClick} />;
  if (!isOTRValid) return <InvalidOTR />;
  if (tenantLegalDocuments) {
    if (
      tenantLegalDocuments.sla.acceptedVersion === tenantLegalDocuments.sla.currentVersion &&
      tenantLegalDocuments.dpa.acceptedVersion === tenantLegalDocuments.dpa.currentVersion
    )
      if (isConnectedStarted) return <AlmostReady onGoToAtlasWebPortalButtonClick={() => null} />;
      else return <ConnectEntraID onConnectButtonClick={() => setIsConnectedStarted(true)} />;
    if (!isSLARulesAccepted)
      return (
        <RulesPage
          organizationName={tenantLegalDocuments.tenant.name}
          rulesContent={tenantLegalDocuments.sla.currentText}
          rulesName="Software License Agreement (SLA)"
          isCheckBoxChecked={isSLACheckBoxSelected}
          handleCheckBoxOnClick={handleSLACheckBoxClick}
          handleButtonOnClick={handleSLAButtonOnClick}
        />
      );
    return (
      <RulesPage
        organizationName={tenantLegalDocuments.tenant.name}
        rulesContent={tenantLegalDocuments.dpa.currentText}
        rulesName="Data Processing Agreement (DPA)"
        isCheckBoxChecked={isPDACheckBoxSelected}
        handleCheckBoxOnClick={handleDPACheckBoxClick}
        handleButtonOnClick={handleDPAButtonOnClick}
      />
    );
  }
  return (
    <ComponentWrapper alignItems="center" justifyContent="center" width="100vw" height="100vh">
      <Loader />;
    </ComponentWrapper>
  );
};
export default LicensePortalPage;
