import React from 'react';
import ReactDOM from 'react-dom';
import GlobalStyle from 'theme/GlobalStyle';
import Apollo from 'components/Apollo/Apollo';
import ThemeProviderExtended from 'components/ThemeProviderExtended/ThemeProviderExtended';
import SnackbarProvider from 'ui/providers/SnackbarProvider';
import { MsalProvider } from '@azure/msal-react';
import { getMsalInstance } from 'environment/SsoConfig';
import App from './App';

ReactDOM.render(
  <MsalProvider instance={getMsalInstance()}>
    <Apollo>
      <ThemeProviderExtended>
        <SnackbarProvider>
          <GlobalStyle />
          <App />
        </SnackbarProvider>
      </ThemeProviderExtended>
    </Apollo>
  </MsalProvider>,
  document.getElementById('root')
);
